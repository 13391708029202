import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import './Home.css'


const Home = ({ baseUrl, authToken }) => {

    let submitButtonClicked = false
    const [propertiesList, setPropertiesList] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [isSuggestionVisible, setSuggestionVisible] = useState(false);

    const [location, setLocation] = useState({ lat: '', lng: '' })

    const [numberOfUsers, setNumberOfUsers] = useState(1)
    // const [submitButtonClicked, setSubmitButtonClicked] = useState(false)
    const [wardsOptionElements, setwardsOptionElements] = useState("")
    const [propertyTypeOptionElements, setPropertyTypeOptionElements] = useState("")
    const [formData, setFormData] =
        useState({
            user_name: [""],
            user_number: [""],
            age: [""],
            total_members: "",
            w_id: "",
            floor: "",
            street: "",
            house_number: "",
            // pincode: "",
            status: "open",
            property_type: "",
            latitude: "",
            longitude: "",
            total_apartment: ''
        })


    const navigate = useNavigate()
    const memberElements = []
    for (let i = 0; i < numberOfUsers; i++) {

        memberElements.push(
            <>
                <div
                    className=" col-10 col-sm-7 col-md-4 col-lg-3"
                    key={i}
                >
                    <div className="form-floating ">
                        <input
                            type="text"
                            className="form-control"
                            id={`user_name_${i}`}
                            placeholder={`user_name_${i}`}
                            key={`user_name_${i}`}
                            name={`user_name_${i}`}
                            tabIndex={i}
                            value={formData.user_name[i]}
                            onChange={handleChange}
                        />
                        <label htmlFor={`user_name_${i}`} >User Name</label>
                    </div>
                </div>
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input
                            type="number"
                            className="form-control"
                            id={`user_number_${i}`}
                            placeholder={`user_number_${i}`}
                            key={`user_number_${i}`}
                            name={`user_number_${i}`}
                            tabIndex={i}
                            value={formData.user_number[i]}
                            onChange={handleChange}
                        />
                        <label htmlFor={`user_number_${i}`}>Contact Number</label>
                    </div>
                </div>
                {/* <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input
                            type="number"
                            className="form-control"
                            id={`age_${i}`}
                            placeholder={`age_${i}`}
                            key={`age_${i}`}
                            name={`age_${i}`}
                            tabIndex={i}
                            value={formData.age[i]}
                            onChange={handleChange}
                        />
                        <label htmlFor={`age_${i}`}>Age</label>
                    </div>
                </div> */}
            </>
        )
    }

    const fetchProperties = async (lat, lng, ward_id) => {
        try {
            const response = await fetch(`https://apis.ecowrap.in/b2g/eco-admin/survey/search-property?lat=${lat}&lng=${lng}&ward_id=${ward_id}`,
                {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${authToken}` }
                });
            const data = await response.json();
            if (data.statusCode === 200) {
                setPropertiesList(data.data);
                setFilteredProperties(data.data);
            } else if (data.statusCode === 401) {
                navigate('/login');
            } else {
                throw new Error(response);
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lng: longitude })
                    fetchProperties(latitude, longitude, formData.w_id);
                },
                (error) => console.error('Error getting location:', error)
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    };
    useEffect(() => {
        getLocation();
        const interval = setInterval(() => getLocation(), 60000);
        return () => clearInterval(interval);
    }, [authToken, navigate]);
    useEffect(() => {
        if (!authToken) {
            navigate('/login')
        }
        getWardsData('/address/community/ward?is_survey=1', authToken);
        getPropertyTypeData('/survey/property-type', authToken)
    }, [])

    const createWardsOptionElement = (data) => {
        if (!Array.isArray(data)) return []
        const options = data.map((item) => {
            return <option key={item?.ward_id} value={item?.ward_id}>{`${item?.ward_number}-${item?.community_name}`}</option>
        })
        return options
    }

    const createPropertyTypeOptionElement = (data) => {
        if (!Array.isArray(data)) return []
        const options = data.map((item) => {
            return <option value={item}>{item}</option>
        })
        return options
    }

    //---------event handler function-------//

    function handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name === "w_id") {
            fetchProperties(location.lat, location.lng, value);
        }

        if (/user_number_\d+/.test(name) && formData.user_number[parseInt(name.slice(-1))] === "" && value < 6) {
            return;
        } else if (/user_number_\d+/.test(name) && value.length > 10) {
            return;
        }
        // else if (name === 'pincode' && value.length > 6) {
        //     return;
        // }
        else if (['user_name_', 'user_number_', 'age_'].some(prefix => name.startsWith(prefix))) {
            const prefix = name.slice(0, name.lastIndexOf('_'));
            const index = parseInt(name.slice(name.lastIndexOf('_') + 1));

            setFormData(prev => ({
                ...prev,
                [prefix]: prev[prefix].map((item, i) => i === index ? value : item)
            }));
        } else if (name === 'house_number') {
            setFormData({ ...formData, [name]: value })
            const filtered = propertiesList
                .filter((property) =>
                    property.house_number.toLowerCase().includes(value.toLowerCase())
                )
                .sort((a, b) => {
                    const input = value.toLowerCase();
                    const aMatch = a.house_number.toLowerCase().indexOf(input);
                    const bMatch = b.house_number.toLowerCase().indexOf(input);
                    return aMatch - bMatch;
                });

            setFilteredProperties(filtered);

        }
        else {
            setFormData({ ...formData, [name]: value });
        }
    }

    const changeNumberOfMembers = (e) => {
        if (e.target.name === 'addMember' && numberOfUsers < 5) {
            setNumberOfUsers(pre => (pre + 1))
            setFormData(pre => {

                return { ...pre, 'user_name': [...pre.user_name, ''], 'user_number': [...pre.user_number, ''], 'age': [...pre.age, ''] }
            })
        }
        if (e.target.name === 'removeMember' && numberOfUsers > 1) {
            setNumberOfUsers(pre => (pre - 1))
            setFormData(pre => {
                return { ...pre, 'user_name': pre.user_name.slice(0, -1), 'user_number': pre.user_number.slice(0, -1), 'age': pre.age.slice(0, -1) }
            })
        }
    }


    const handleSubmit = (e) => {
        try {
            // setSubmitButtonClicked(true);
            submitButtonClicked = true
            // if (formData.user_name.some(value => ["", null, undefined].includes(value))) {
            //     const index = formData.user_name.findIndex(value => ["", null, undefined].includes(value));
            //     document.getElementById(`user_name_${index}`)?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please fill user name' })
            //     return
            // }
            // if (!formData.user_number.every(number => /^[6-9]\d{9}/.test(number))) {
            //     const firstInvalidIndex = formData.user_number.findIndex(value => !/^[6-9]\d{9}/.test(value));
            //     document.getElementById(`user_number_${firstInvalidIndex}`)?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please fill valid number' })
            //     return
            // }
            // if (!(formData.total_members)) {
            //     document.getElementById('total_members')?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please select total members' })
            //     return
            // }
            // if (formData.age.some(value => (
            //     value.trim() === "" ||          // Check for empty strings or strings with just spaces
            //     isNaN(Number(value)) ||         // Check if value is NaN
            //     (Number(value) < 0) ||            // Check if value is less than 0
            //     (Number(value) > 100)             // Check if value is greater than 100
            // ))) {
            //     const index = formData.age.findIndex(value => (
            //         value.trim() === "" ||
            //         isNaN(Number(value)) ||
            //         Number(value) < 0 ||
            //         Number(value) > 100
            //     ));
            //     document.getElementById(`age_${index}`)?.focus();
            //     setSubmitButtonClicked(false);
            //     Swal.fire({ "icon": 'warning', 'text': 'Please select a valid age' });
            //     return;
            // }

            if (!formData.house_number) {
                document.getElementById('house_number')?.focus()
                // setSubmitButtonClicked(false)
                submitButtonClicked = false
                Swal.fire({ "icon": 'warning', 'text': 'Please fill house detail' })
                return
            }
            // if (!formData.w_id) {
            //     document.getElementById('w_id')?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please select ward' })
            //     return
            // }
            // if (!/^\d+$/.test(formData.pincode)) {
            //     document.getElementById('pincode')?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please fill valid pincode' })
            //     return
            // }
            // if (!formData.property_type) {
            //     document.getElementById('property_type')?.focus()
            //     setSubmitButtonClicked(false)
            //     Swal.fire({ "icon": 'warning', 'text': 'Please select property type' })
            //     return
            // }
            if (!formData.latitude) {
                document.getElementById('geoLocation')?.focus()
                // setSubmitButtonClicked(false)
                submitButtonClicked = false
                Swal.fire({ "icon": 'warning', 'text': 'Please get geoLocation' })
                return
            }

            const users = formData.user_name.map((item, index) => {
                return {
                    'name': formData.user_name[index] === "" ? 'NA' : formData.user_name[index],
                    'number': formData.user_number[index] === "" ? '6000000000' : formData.user_number[index],
                    'age': formData.age[index] === "" ? '1' : formData.age[index]
                }
            })


            const body = {
                users: users,
                ward_id: formData.w_id,
                street: formData.street.trim() === "" ? 'NA' : formData.street.trim(),
                flor: formData.floor === "" ? 'NA' : formData.floor,
                house_name: 'NA',
                house_number: formData.house_number.trim(),
                // pincode: formData.pincode.trim(),
                status: formData.status,
                property_type: formData.property_type === "" ? 'House' : formData.property_type,
                total_members: formData.total_members === "" ? '-1' : formData.total_members,
                lat: formData.latitude,
                lng: formData.longitude
            }
            if (formData.total_apartment !== '') {
                body.total_appartments = Number(formData.total_apartment);
            }
            console.log(body, "body");


            postData('/survey/property', authToken, body)
        }
        catch (e) {
            Swal.fire({ icon: 'error', title: 'Error', text: e, })
        }
        finally {
            // setSubmitButtonClicked(false);
            submitButtonClicked = false
        }
    }

    //-----Log out function------///
    function handleLogOut() {
        localStorage.removeItem('token')
        navigate('/login')
    }

    //--------getGeoLocation-----//
    function getGeolocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setFormData((pre) => ({ ...pre, latitude: position.coords.latitude, longitude: position.coords.longitude }))
                },
                function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to retrieve geolocation. Please enable location services or try again later.'
                    });
                },
                {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0
                }
            );
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Geolocation is not supported by your browser.'
            });
        }
    }

    // Removed handleBlur to prevent premature hiding
    const handleSuggestionClick = (property) => {
        setSuggestionVisible(false);  // Hide suggestions after click
    };
    const handleFocus = () => {
        setSuggestionVisible(true);
    };
    const handleBlur = () => {
        setSuggestionVisible(false)
    }
    //-------server communication function -------//
    async function getWardsData(url, authToken) {
        try {
            const data = await axios.get(`${baseUrl}${url}`, { headers: { 'Authorization': `Bearer ${authToken}` } })
            if (String(data?.data?.statusCode) === '200') {
                const wardsOptionsData = createWardsOptionElement(data?.data?.data)
                console.log(wardsOptionsData, "wardsOptionsData");

                setwardsOptionElements(wardsOptionsData)
            }
            else if (['401', '203'].includes(String(data?.data?.statusCode))) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `Session expired .Please login again...`
                })
                navigate('/login')
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `${data?.data?.message}`
                })
            }
        }
        catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Ooops',
                text: `${e}`
            })
        }
    }

    async function getPropertyTypeData(url, authToken) {
        try {
            const data = await axios.get(`${baseUrl}${url}`, { headers: { 'Authorization': `Bearer ${authToken}` } })
            if (String(data?.data?.statusCode) === '200') {
                const proeprtyTypeOptionsData = createPropertyTypeOptionElement(data?.data?.data)
                setPropertyTypeOptionElements(proeprtyTypeOptionsData)
            }
            else if (['401', '203'].includes(String(data?.data?.statusCode))) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `Session expired .Please login again...`
                })
                navigate('/login')
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `${data?.data?.message}`
                })
            }
        }
        catch (e) {
            Swal.fire({
                icon: 'error',
                title: 'Ooops',
                text: `${e}`
            })
        }
    }

    async function postData(url, authToken, body) {
        try {
            const response = await axios.post(`${baseUrl}${url}`, body, { headers: { 'Authorization': `Bearer ${authToken}` } })


            if (String(response?.data?.statusCode) === '200') {
                setFormData({
                    user_name: [""],
                    user_number: [""],
                    age: [""],
                    total_members: "",
                    w_id: "",
                    floor: "",
                    street: "",
                    house_name: "",
                    house_number: "",
                    // pincode: "",
                    status: 'open',
                    property_type: "",
                    latitude: "",
                    longitude: "",
                    total_apartment: ''
                })
                setNumberOfUsers(1);
                getLocation()
                Swal.fire({ icon: 'success', text: 'Your form has been submitted successfully...', title: 'Success!', confirmButtonText: 'OK' })
            }
            else if (['401', '203'].includes(String(response?.data?.statusCode))) {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `Session expired .Please login again...`
                })
                navigate('/login')
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops',
                    text: `${response?.data?.message}`
                })
            }
        } catch (e) {

            Swal.fire({ icon: 'error', title: 'Ooops', text: e })
        }
    }
    return (<>
        <div className="container-fluid d-flex flex-column mt-3 mb-5">
            <div className="row d-flex justify-content-center home-page-ecowrap-logo mb-5">
                <img src="asset/ecowrap-logo.png" alt="Ecowrap-logo" />
            </div>
            <div className=" row d-flex flex-wrap justify-content-space-around align-items-center align-content-space-around g-4">
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <select className="form-select"
                        aria-label="Default select example"
                        id="w_id"
                        name="w_id"
                        value={formData.w_id}
                        onChange={handleChange}
                    >
                        <option value="" defaultValue>Select Wards</option>
                        {wardsOptionElements}
                    </select>
                </div>
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input type="text"
                            className="form-control"
                            id="house_number"
                            placeholder="houseNumber"
                            name="house_number"
                            value={formData.house_number}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                        <label htmlFor="house_number" >Property Number/Name</label>
                    </div>
                    {isSuggestionVisible && formData.w_id != '' && (
                        <ul className="suggestions-list col-11 col-sm-8 col-md-5 col-lg-4">
                            <li
                                className="suggestion-item w-full"
                            >
                                Already surveyed properties
                            </li>
                            {filteredProperties.length > 0 ? (
                                filteredProperties.map((property, index) => (
                                    <li
                                        key={index}
                                        // onMouseDown={() => handleSuggestionClick(property)}  // Use onMouseDown to avoid losing focus before click
                                        className={`suggestion-item d-flex justify-content-between list-group-item striped-item ${index % 2 === 0 ? 'even' : 'odd'}`}
                                    >
                                        <div className='me-5'>{property.house_number}</div>
                                        <div className='ms-5'>{property?.street}</div>
                                    </li>
                                ))
                            ) : (
                                <></>
                            )}
                        </ul>
                    )}
                </div>

                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        id="status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                    >
                        <option defaultValue value="open">open</option>
                        <option value="close">close</option>
                        <option value="construction">construction</option>
                    </select>
                </div>
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        id="property_type"
                        name="property_type"
                        value={formData.property_type}
                        onChange={handleChange}
                    >
                        <option defaultValue value="">Select Property Type</option>
                        {propertyTypeOptionElements}
                    </select>
                </div>

                {formData.property_type === "Apartment" && <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <input
                        className="form-control"
                        min={0}
                        type="number"
                        onChange={handleChange}
                        placeholder="NO. of Apartment"
                        name="total_apartment"
                    />
                </div>}

                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input type="text"
                            className="form-control"
                            id="floor"
                            placeholder="floor"
                            name="floor"
                            value={formData.floor}
                            onChange={handleChange}
                        />
                        <label htmlFor="floor" >Floor</label>
                    </div>
                </div>
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input type="text"
                            className="form-control"
                            id="street"
                            placeholder="street"
                            name="street"
                            value={formData.street}
                            onChange={handleChange}
                        />
                        <label htmlFor="street" >Street</label>
                    </div>
                </div>
                {/* <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input
                            type="number"
                            className="form-control"
                            id="pincode"
                            placeholder="pincode"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                        />
                        <label htmlFor="pincode" >Pincode</label>
                    </div>
                </div> */}
                <div className="col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox"
                            data-toggle="toggle"
                            name="flexRadioDefault"
                            id="geoLocation"
                            checked={formData.latitude ? true : false}
                            onClick={getGeolocation}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="geoLocation">
                            Geolocation
                        </label>
                    </div>
                </div>
                <div className=" col-10 col-sm-7 col-md-4 col-lg-3">
                    <div className="form-floating ">
                        <input
                            type="number"
                            className="form-control"
                            id="total_members"
                            placeholder="total_members"
                            name="total_members"
                            value={formData.total_members}
                            onChange={handleChange}
                        />
                        <label htmlFor="total_members">Total Members</label>
                    </div>
                </div>

            </div>
            <div className="row d-flex justify-content-center mt-5 mb-5 ">
                <div className=" col-10 d-flex justify-content-end">
                    <button
                        key={'addMember'}
                        className="btn btn-primary ms-2"
                        name="addMember"
                        onClick={changeNumberOfMembers}
                        disabled={numberOfUsers >= 5 ? true : false}
                    >Add</button>
                    <button
                        key={'removeMember'}
                        className="btn btn-primary ms-2"
                        name="removeMember"
                        onClick={changeNumberOfMembers}
                        disabled={numberOfUsers <= 1 ? true : false}
                    >Remove</button>
                </div>
            </div>
            <div className=" row d-flex flex-wrap justify-content-space-around align-items-center align-content-space-around g-4">
                {memberElements}
            </div>

            <div className="row d-flex justify-content-center mt-5 mb-5">
                <button
                    className="btn btn-primary col-10 col-sm-7 col-md-4 col-lg-3"
                    disabled={submitButtonClicked}
                    onClick={handleSubmit}>Submit</button>
            </div>
            <div className="row d-flex justify-content-end mt-5 p-3">
                <button
                    className="btn btn-secondary col-5 col-sm-3 col-md-1 col-lg-1"
                    onClick={handleLogOut}>Log out</button>
            </div>
        </div>
    </>)
}

export default Home